export default new class Industries {
    constructor() {
        this.list = [
            'Agriculture',
'Arts',
'Beauty',
'Construction',
'Consumer_Goods',
'Corporate_Services',
'Design',
'Education',
'Energy_and_Mining',
'Entertainment',
'Finance',
'Food_and_beverage',
'Hardware_and_Networking',
'Health_Care',
'Legal',
'Manufacturing',
'Media_and_Communications',
'Nonprofit',
'Public_Administration',
'Public_Safety',
'Real_Estate',
'Recreation_and_Travel',
'Retail',
'Software_and_IT_Services',
'Transportation_and_Logistics',
'Wellness_and_Fitness',
        ]
    }
}