// import { RouterView } from 'vue-router';
// import { h, resolveComponent } from 'vue';
import App from "./App.vue";
import ErrorPage from "./ErrorPage.vue";
import Page from "./Page.vue";
// import i18n from "./i18n";

export default new (class Prismic {
  constructor() {
    this.routes = [
      {
        path: "/",
        // redirect: `/${i18n.locale}`,
        redirect: "/en",
      },
      {
        path: "/:lang/",
        name: "App",
        component: App,
      },
      {
        path: "/:lang/404",
        component: ErrorPage,
      },
      {
        path: "/:lang/cdap-application",
        component: Page,
        slices: [
          {
            component: "CDAPApplication",
          },
          {
            component: "FloatingCard",
            data: {
              title: "Canadian Digital Adoption Plan",
              text: 'cdap.FloatingCard.text',
              cta_buttons: [
                {
                  text: "Read More on CDAP",
                  link: "/cdap",
                },
              ],
            },
          },
        ],
      },
      {
        path: "/:lang/contact-us",
        component: Page,
        slices: [
          {
            component: "ContactForm",
          },
        ],
      },
      {
        path: "/:lang/cdap",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'cdap.TextComponent.title',
              subtitle: 'cdap.TextComponent.subtitle',
              text: 'cdap.TextComponent.text',
              date: "",
              asdf: `<ol>
                      <li><p>캐나다의 모든 주에 있는 사설 중소기업들은 CDAP 프로그램 시작할 수 있습니다.</p></li>
                      <li><p>자격조건에 충족한 SME (사설 중소기업) 은 Stream 2 애플리케이션 포털로 이동합니다.</p></li>
                      <li><p>Stream 2에 승인된 SME는 CDAP의 Digital Advisor Marketplace에 액세스하여 함께 일할 Digital Advisor를 찾을 수 있습니다. 저희 In Your Daylight은 토론토 다운타운에 위치하며 캐나다 연방 정부가 인증한 Digital Advisor 들로 구성되어 최고의 서비스를 자랑하는 에이전시 입니다.</p></li>
                      <li><p>SME (사설 중소기업) 와 Digital Advisor는 디지털 채택 계획의 비용 및 범위에 대해 합의합니다.</p></li>
                      <li><p>SME (사설 중소기업) 는 완료된 디지털 계획을 CDAP 팀에게 제출합니다.</p></li>
                      <li><p>디지털 채택 계획 생성 비용의 최대 90%(최대 $15,000)를 충당하기 위한 보조금을 요청합니다.</p></li>
                      <li><p>디지털 채택 계획을 실현할 중소기업은 사업 규모 및 세금 공제 내역에 따라 BDC에서 최대 $100,000 의 무이자 대출을 신청하여 계획을 실행할 수 있습니다.</p></li>
                      <li><p>CDAP 스트림 2에 참여하는 중소기업은 계획을 실행하는 데 도움이 되는 청년 고용 임금 보조금을 $7,300 받을 수 있습니다.</p></li>
                      </ol>`,
              columns: 2,
              textalign: "text-align-left",
              cta_buttons: [
                {
                  text: "Apply to CDAP Today!",
                  link: "/cdap-application",
                },
              ],
            },
          },
          {
            component: "FloatingCard",
            data: {
              title: "More about CDAP Canada",
              text: 'cdap.FloatingCard.text'
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-left",
              date: "",
              title: "",
              text: 'cdap.TextAndImage.text1',
              // image: require("./assets/BDC Zero Interest Loan.webp"),
              image: require("./assets/cdap-canada.png"),
              ref: '',
              cta_buttons: [
                {
                  text: 'cdap.TextAndImage.cta_buttons.text',
                  link: "/contact-us"
                }
              ]
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              date: "",
              title: 'cdap.TextAndImage.title',
              text: 'cdap.TextAndImage.text2',
              // image: require("./assets/CDAP-image2.png"),
              image: require("./assets/easy steps.png"),
              ref: 'cdap.TextAndImage.ref'
            },
          },
        ],
        bg: "dark",
      },
      {
        path: "/:lang/blog",
        component: Page,
        // children: [
        //   {
        //     path: "nice",
        //     component: Page,
        //     slices: [
        //       {
        //         component: 'TextComponent',
        //         data: {
        //           title: "Blog",
        //           subtitle: "Read up on some of our latest our blog posts!",
        //           text: ``,
        //           columns: 1,
        //           textalign: "text-align-center",
        //         }
        //       },
        //     ]
        //   }
        // ],
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'blog.title',
              subtitle: 'blog.subtitle',
              date: "",
              text: ``,
              columns: 1,
              textalign: "text-align-center",
            },
          },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-left",
          //     textalign: "text-align-right",
          //     title: "Digital marketing vs traditional marketing.",
          //     text: "Highly effective way to reach customers",
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "AUG 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/digital-vs-traditional-marketing"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-right",
          //     textalign: "text-align-left",
          //     title: "How much will it cost me to advertise on social media?",
          //     text: `Much cheaper than traditional advertising`,
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "JUL 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/advertising-on-social-media"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-left",
          //     textalign: "text-align-right",
          //     title: "The advantages of digital marketing.",
          //     text: "Powerful tool for small and medium sized businesses",
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "JUN 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/the-advantages-of-digital-marketing"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-right",
          //     textalign: "text-align-left",
          //     title: "Why does digital marketing matter?",
          //     text: `You're probably thinking to yourself, "Why should I care?"`,
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "MAY 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/why-digital-marketing-matters"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-left",
          //     textalign: "text-align-right",
          //     title: "What is digital marketing?",
          //     text: "Communicate with customers.",
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "APR 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/what-is-digital-marketing"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-right",
          //     textalign: "text-align-left",
          //     title: "A clear vision and marketing plan can help drive growth in your business",
          //     text: "A clear vision and marketing plan can help drive growth in your business.",
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "MAR 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/clear-vision-marketing-plan"
          //       }
          //     ]
          //   }
          // },
          // {
          //   component: 'TextAndImage',
          //   data: {
          //     orientation: "text-on-left",
          //     textalign: "text-align-right",
          //     title: "Develop content for your site and social media channels.",
          //     text: "Content is the most important part of your marketing strategy.",
          //     image: require("./assets/Illustration-sample.png"),
          //     date: "FEB 01, 2021",
          //     cta_buttons: [
          //       {
          //         text: "Read More",
          //         link: "/blog/social-media-channels"
          //       }
          //     ]
          //   }
          // },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              title: 'blog.implement-a-marketing-strategy.title',
              text: 'blog.implement-a-marketing-strategy.subtitle',
              image: require("./assets/marketing-strategy.png"),
              date: 'blog.implement-a-marketing-strategy.date',
              ref: '',
              cta_buttons: [
                {
                  text: "Read More",
                  link: "/blog/implement-a-marketing-strategy",
                },
              ],
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-right",
              title: 'blog.create-effective-sales-funnel.title',
              text: 'blog.create-effective-sales-funnel.subtitle',
              image: require("./assets/sales-funnel.png"),
              date: 'blog.create-effective-sales-funnel.date',
              ref: '',
              cta_buttons: [
                {
                  text: "Read More",
                  link: "/blog/create-effective-sales-funnel",
                },
              ],
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              title: 'blog.clear-growth-strategy.title',
              text: 'blog.clear-growth-strategy.subtitle',
              image: require("./assets/goals.png"),
              date: 'blog.clear-growth-strategy.date',
              ref: '',
              cta_buttons: [
                {
                  text: "Read More",
                  link: "/blog/clear-growth-strategy",
                },
              ],
            },
          },
        ],
        bg: "dark",
      },
      //------ BLOGS ---------

      {
        path: "/:lang/blog/social-media-channels",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'blog.social-media-channels.title',
              subtitle: 'blog.social-media-channels.subtitle',
              date: 'blog.social-media-channels.date',
              columns: 1,
              textalign: "text-align-left",
            },
          },
          {
            component: "TextAlone",
            data: {
              text: 'blog.social-media-channels.text'
            },
          },
        ],
      },
      {
        path: "/:lang/blog/create-effective-sales-funnel",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'blog.create-effective-sales-funnel.title',
              subtitle: 'blog.create-effective-sales-funnel.subtitle',
              date: 'blog.create-effective-sales-funnel.date',
              columns: 1,
              textalign: "text-align-left",
            },
          },
          {
            component: "TextAlone",
            data: {
              text: 'blog.create-effective-sales-funnel.text'
            },
          },
        ],
      },
      {
        path: "/:lang/blog/clear-growth-strategy",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'blog.clear-growth-strategy.title',
              subtitle: 'blog.clear-growth-strategy.subtitle',
              date: 'blog.clear-growth-strategy.date',
              columns: 1,
              textalign: "text-align-left",
            },
          },
          {
            component: "TextAlone",
            data: {
              text: 'blog.clear-growth-strategy.text'
            },
          },
        ],
      },
      {
        path: "/:lang/blog/implement-a-marketing-strategy",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'blog.implement-a-marketing-strategy.title',
              subtitle: 'blog.implement-a-marketing-strategy.subtitle',
              date: 'blog.implement-a-marketing-strategy.date',
              columns: 1,
              textalign: "text-align-left",
            },
          },
          {
            component: "TextAlone",
            data: {
              text: 'blog.implement-a-marketing-strategy.text'
            },
          },
        ],
      },
      //------ BLOGS END---------
      {
        path: "/:lang/services",
        component: Page,
        slices: [
          {
            component: "TextComponent",
            data: {
              title: 'Services.title',
              subtitle: 'Services.subtitle',
              date: "",
              text: ``,
              columns: 1,
              textalign: "text-align-center",
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage1.title',
              text: 'Services.TextAndImage1.text',
              image: require("./assets/1.png"),
              ref: '',
              // cta_buttons: [
              //   {
              //     text: "sadas",
              //     link: "dd"
              //   }
              // ]
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage4.title',
              text: 'Services.TextAndImage4.text',
              image: require("./assets/4.webp"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              img: require("./assets/shopify-partner.png"),
              date: "",
              // img: require("./assets/blog-shopify.jpg"),
              // img: require("./assets/Shopify-Certified-Partner-Logo.png"),
              title: 'Services.TextAndImage3.title',
              text: 'Services.TextAndImage3.text',
              image: require("./assets/3.webp"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage5.title',
              text: 'Services.TextAndImage5.text',
              image: require("./assets/5.webp"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage6.title',
              text: 'Services.TextAndImage6.text',
              image: require("./assets/6.png"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage7.title',
              text: 'Services.TextAndImage7.text',
              image: require("./assets/7.png"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-right",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage8.title',
              text: 'Services.TextAndImage8.text',
              image: require("./assets/8.png"),
              ref: '',
            },
          },
          {
            component: "TextAndImage",
            data: {
              orientation: "text-on-left",
              textalign: "text-align-left",
              date: "",
              title: 'Services.TextAndImage2.title',
              text: 'Services.TextAndImage2.text',
              image: require("./assets/2.webp"),
              ref: '',
            },
          },
        ],
        bg: "dark",
      },
      {
        path: "/:lang/:catchAll(.*)",
        component: ErrorPage,
      },
    ];
    (this.nav = [
      {
        text: "Home",
        link: "/",
      },
      // {
      //     text: "Who we are",
      //     link: "./who-we-are"
      // },
      {
        text: "CDAP",
        link: "/cdap",
      },
      {
        text: "CDAP Application",
        link: "/cdap-application",
      },
      {
        text: "services",
        link: "/services",
        submenu: [
          {
            text: "sub menu item",
            link: "/cdap",
          },
          {
            text: "sub menu item 2",
            link: "/cdap",
          },
        ],
      },
      {
        text: "Blog",
        link: "/blog",
      },
      {
        text: "contact us",
        link: "/contact-us",
      },
    ]),
      (this.socialnav = [
        // {
        //   icon: require("./assets/Linkedin-icon.svg"),
        //   text: "Linkedin",
        //   link: "https://www.linkedin.com/company/in-your-daylight/mycompany/",
        // },
      ]);
  }
})();

// {
//   path: "/who-we-are",
//   component: Page,
//   slices: [
//     {
//       component: 'FullsizeImage',
//       data: {
//         image: require("./assets/team-photo-sample.jpeg"),
//         cover: true
//       }
//     },
//     {
//       component: 'TextAndImage',
//       data: {
//         orientation: "text-on-right",
//         textalign: "text-align-left",
//         title: "Not Your Ordinary Creative Team",
//         text: "at. Duis autem vel eum iriure dolor in hendrerit in vulputate vell eum iriure dolor in hendrerit in vulputate vell eum iriure dolor in hendrerit in vulputate vell eum iriure dolor in hendrerit in vulputate vell eum iriure dolor in hendrerit in vulputate vell eum iriure dolor in hendrerit in vulputate vell ",
//         image: require("./assets/team-photo-sample.jpeg")
//       }
//     }
//   ]
// },
