<template>
  <div class="timesun">
<!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 51 26" style="enable-background:new 0 0 51 26;" xml:space="preserve">
        <g>
            <g>
                <path class="st0" :style="`stroke: ${color}`" d="M13.6,25.5c0-6.7,5.3-12.1,11.9-12.1s11.9,5.4,11.9,12.1"/>
                <path class="st0" :style="`stroke: ${color}`" d="M25.5,8.3V3.4"/>
                <path class="st0" :style="`stroke: ${color}`" d="M37.5,13.4l3.4-3.5"/>
                <path class="st0" :style="`stroke: ${color}`" d="M42.4,25.5h4.9"/>
                <path class="st0" :style="`stroke: ${color}`" d="M8.7,25.5H3.8"/>
                <path class="st0" :style="`stroke: ${color}`" d="M13.6,13.4l-3.4-3.5"/>
                <path class="st0" :style="`stroke: ${color}`" d="M31.9,9.6l3.7-9.1"/>
                <path class="st0" :style="`stroke: ${color}`" d="M41.1,18.9l9-3.8"/>
                <path class="st0" :style="`stroke: ${color}`" d="M9.9,19l-9-3.7"/>
                <path class="st0" :style="`stroke: ${color}`" d="M19,9.7l-3.7-9.1"/>
            </g>
        </g>
        </svg>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    color: {
        type: String,
        default: "#DBAA47"
    }
  },
  watch: {},
  methods: {

  },
  data() {
    return {
      
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";
.st0 {
    fill:none;
    stroke:#DBAA47;
    stroke-width:1;
    stroke-miterlimit:10;
}
</style>
