<template>
  <div class="logo">
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.3392 79.5348"><path class="d" d="M12.379,38.8079c0,4.085,.3712,5.075,3.1565,6.6224v.1237H.3716v-.1237c2.785-1.5475,3.0947-2.5375,3.0947-6.6224V21.9724c0-3.9612-.5572-5.199-3.4662-6.7465v-.1237H12.379v23.7058ZM7.7368,0c3.0328,0,5.3228,2.2281,5.3228,5.1372s-2.29,5.1372-5.3228,5.1372S2.4141,8.0462,2.4141,5.1372,4.704,0,7.7368,0Z"/><path class="d" d="M30.6635,55.6328l-12.3159-24.8174c-1.9805-3.9609-2.5991-4.8271-4.6416-6.5605v-.124h14.7295v.124c-1.4233,.8047-1.7944,1.5479-1.7944,2.7852,0,.9902,.3711,2.3516,.9902,3.6514l7.0552,14.8535,6.0034-14.2959c.4331-1.0527,.6187-2.043,.6187-2.9707,0-1.8574-.9902-3.2803-2.8467-4.0234v-.124h8.9121v.124c-2.1665,1.6094-3.0947,2.9092-4.8276,6.9941l-12.6255,29.707c-2.2896,5.3223-4.8271,7.9219-8.8501,7.9219-3.5278,0-6.127-1.9805-6.127-5.3848,0-3.1562,2.228-4.9512,4.8892-4.9512,.7427,0,1.2998,.124,1.9805,.3711-1.2378,.5576-1.7949,1.4854-1.7949,2.9092,0,1.918,1.3618,3.2803,3.3423,3.2803,2.3516,0,3.8369-1.6709,5.4463-5.2607l1.8564-4.209Z"/><path class="d" d="M58.4799,39.208c-.0596-3.8984-.8862-5.3105-3.8154-6.626l-.002-.124,12.624-.1934,.6079,39.6045c.0576,3.7744,.6348,5.0654,3.4429,6.5693l.002,.124-12.2524,.1885-.1216-7.9209c-1.166,4.7207-4.9438,8.6172-10.5127,8.7031-7.3643,.1133-13.458-5.7363-13.605-15.3271-.1499-9.7783,5.9404-16.2461,13.5518-16.3633,5.3843-.082,9.0249,3.2646,10.3296,7.5791l-.249-16.2139Zm.418,27.2275l-.0933-6.0635c-.0918-6.0029-3.7393-9.7236-7.3901-9.667-4.7031,.0713-7.166,4.9385-7.0371,13.3545,.1147,7.4873,2.8423,11.7773,7.4834,11.707,3.6509-.0566,7.1235-3.6982,7.0371-9.3311Z"/></svg>
  </div>
</template>

<script>
export default {
  name: 'IYDLogo',
  props: {
  },
  watch: {},
  methods: {

  },
  data() {
    return {
      
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";

.logo svg {
  // width: 50px;
  fill: white;
}
</style>
