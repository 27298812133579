<template>
  <div class="golden-section">
   <div class="section-outer">
      <div class="section">
      <!-- <img class="image" src="../assets/iyd2.png"/> -->
      <div class="text">
          <table>
            <h2 class="business">{{ $t('App.title') }}</h2>
            <tr class="text-tr">
              <td>
                <p>
                  {{ $t('App.content') }}
                </p>
              </td>
            </tr>
          </table>
          <div class="text-tr-mobile">
              <p class="animate-fade-in">
                {{ $t('App.content') }}
              </p>
            </div>
      </div>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'GoldSquareSection',
  props: {
    clickEvents: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    
  },
  methods: {

  },
  data() {
    return {
      split_title: []
    }
  },
  mounted() {
    this.split_title = this.data.title.split(" ");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";
.golden-section {
    position: relative;
    margin: auto;
    @media screen and (min-width: $mobileup) {
      min-height: 50vh;
      width: calc(100vw - 180px);
      padding: 40px;
    }
    @media screen and (max-width: $mobiledown) {
      padding: 20px;
      width: calc(100vw - 40px);
    }
}

h2.business {
  font-size: 32px;
}


.section-outer {
  padding: 40px 0;
  border-radius: 24px;
  border: 1px solid $gold;
}
.image {
  position: absolute;
  max-width: 25%;
  max-height: 400px;
  left: 75%;
  transform: translateX(-50%);
  bottom: -10%;
}
.heading {
  padding-bottom: 40px;
}
.section {
    // width: calc(100vw - 180px);
    margin: auto;
    background: #DBAA47;
    border-radius: 24px;
    @media screen and (min-width: $mobileup) {
      padding: 10px;
    }
    @media screen and (min-width: $mobileup) {
      padding: 40px;
    }
}
.text {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
p {
  color: $darkblue;
  padding-top: 30px;
  width: 75%;
  margin: 0 auto;
}
h2 {
    color: $darkblue;
    font-size: 60px;
    @media screen and (max-width: $mobiledown) {
      font-size: 30px;
    }
    margin: 0;
    line-height: 1.5;
    display: inline-block;
    // transform: translateX(-100%);
  
}
.right {
  transform: translateY(60%);
  text-align: left;
  // display: inline-block;
}
.left {
  // display: inline-block;
  text-align: right;
}
.col {
  display: inline-block;
}

table {
  width: 100%;
}
.row {
  // padding-bottom: 50px;
  width: 100%;
  // display: inline-block;
  td {
    width: 50%;
    overflow: hidden;
  }
}
.text-tr-mobile {
  @media screen and (min-width: $mobileup) {
    display: none;
  }
  p {
    margin: auto;
    text-align: center;
    padding-bottom: 10px;
    width: calc(100% - 40px);
  }
}
.text-tr {
  @media screen and (max-width: $mobiledown) {
    display: none;
  }
}
</style>
