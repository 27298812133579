<template>
  <div
    class="images"
    :style="
      data.text_on_top
        ? `flex-direction: column-reverse`
        : `flex-direction: column`
    "
  >
    <div class="tiles">
      <div v-for="(item, i) in data.images" :key="i" class="tile">
        <img
          :src="item.src"
          :style="`margin-left: ${item.offset.x}px; margin-top: ${item.offset.y}px;`"
        />
      </div>
    </div>
    <div class="text-content">
      <div class="inner">
        <!-- <div class="divider">
       <div class="suns">
        <FullSun class="sun"></FullSun>
       </div>
      </div> -->
        <h2
          class="animate-fade-in p-insideof-h2"
          v-html="$t(data.cta_text)"
        ></h2>
        <p class="body">{{ $t(data.text1) }}</p>
        <p class="body">{{ $t(data.text2) }}</p>
        <router-link
          class="cta animate-fade-in animate-delay-500"
          :to="`/${$i18n.locale}${data.cta.link}`"
        >
          <p>{{ $t(data.cta.text) }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesGallery",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    clickEvents: {
      type: Object,
      default: () => {},
    },
  },
  watch: {},
  methods: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";
.tile {
  height: 0;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  // width: 33%;
  img {
    max-height: 60vh;
    max-width: 22vw;
    @media screen and (max-width: $mobiledown) {
      max-width: 50vw;
    }
    padding: 20px;
  }
}
.images {
  margin: auto;
  text-align: center;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.text-content {
  @media screen and (min-width: $mobileup) {
    width: calc(100% - 200px);
    padding: 0 100px;
  }
  text-align: center;
  // margin-left: auto;
  margin: auto;
  h2 {
    margin-bottom: 20px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  h2.p-insideof-h2 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    h2 {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
    h4 {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
.inner {
  background-image: url("../assets/placeholder1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  margin: 20px;
  // text-align: left;
  position: relative;
  text-align: center;
  /* .cta { */
    /* margin-top: 30px; */
    /* text-transform: capitalize; */
  /* } */
  .body {
    margin-bottom: 30px;
  }
  .divider {
    // width: 200px;
    position: absolute;
    bottom: -60px;
    // border-bottom: 1px solid $gold;
    height: 1px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }

  .sun {
    display: inline-block;
    min-width: 70px;
    transform: translateY(50%);
    padding: 0 10px;
  }
  .suns {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
</style>
