<template>
  <div class="cdap-application">
    <div class="text">
      <h1 class="animate-fade-in">{{ $t("input.title") }}</h1>
      <p class="animate-fade-in animate-delay-250">
        {{ $t("input.subtitle") }}
      </p>
    </div>
    <div class="application-form animate-fade-in animate-delay-250">
      <form @submit.prevent="submitForm" ref="form">
        <div class="row">
          <label for="name"
            ><p>{{ $t("input.Name") }}</p></label
          >
          <input
            name="name"
            ref="name"
            required
            placeholder="e.g. John Smith"
          />
        </div>
        <div class="row">
          <label for="company_name"
            ><p>{{ $t("input.Company_Name") }}</p></label
          >
          <input
            name="company_name"
            ref="company_name"
            required
            placeholder="e.g. In Your Daylight"
          />
        </div>
        <div class="row">
          <label for="business"
            ><p>{{ $t("input.Business_Industry") }}</p></label
          >
          <div name="business" class="dropdown closed">
            <input
              required
              name="business"
              ref="business"
              class="dropdown-input"
              :value="activeIndustry"
              @input="doIndustrySearch"
              @change="handleChange"
              placeholder="Please select your occupational field."
            />
            <div class="arrow">▼</div>
            <div class="selection">
              <p
                class="option industry"
                v-for="(item, i) in industries.list"
                :key="i"
              >
                {{ $t(`select.${item}`) }}
              </p>
            </div>
          </div>
          <!-- <select name="business" class="dropdown closed">
                <option class="option industry" v-for="(item, i) in industries.list" :key="i">{{ $t(`select.${item}`) }}</option>
            </select> -->
        </div>
        <div class="row">
          <label for="phone"
            ><p>{{ $t("input.Phone_Number") }}</p></label
          >
          <input
              @keyup="phoneMask"
              type="tel"
              name="phone"
              ref="phone"
              required
              placeholder="e.g. 1(647)358-7767"
            />
        </div>
        <div class="row">
          <label for="email"
            ><p>{{ $t("input.Email_Address") }}</p></label
          >
          <input
            name="email"
            ref="email"
            required
            placeholder="e.g. johnsmith@email.com"
          />
        </div>
        <div class="row">
          <label for="appBook"
            ><p>{{ $t("contact.input.app") }}</p></label
          >
          <div name="appBook" class="radiobox">
            <!-- class="dropdown closed"> -->
            <!-- <span class="input">{{application}}</span>
                <div class="arrow">▼</div>
                <div class="selection">
                    <p class="option application">No</p>
                    <p class="option application">Yes</p>
                </div> -->
            <input
              type="radio"
              ref="appBook"
              v-model="appBook"
              name="appBook"
              class="radio"
              value="No"
              required
            />
            {{ $t("contact.input.No") }}
            <input
              type="radio"
              ref="appBook"
              v-model="appBook"
              name="appBook"
              class="radio"
              value="Yes"
            />
            {{ $t("contact.input.Yes") }}
          </div>
        </div>
        <div class="row">
          <label for="calendar"
            ><p>{{ $t("contact.input.calendar") }}</p></label
          >
          <input
            type="datetime-local"
            name="calendar"
            ref="calendar"
          />
        </div>
        <div class="row">
          <label for="timezone"><p>Time zone</p></label>
          <input
            name="timezone"
            ref="timezone"
            placeholder="e.g. EST"
          />
        </div>
        <div class="row">
          <label for="app"
            ><p>
              {{ $t("input.Have_you_previously_started_a_CDAP_application?") }}
            </p></label
          >
          <div name="app" class="radiobox">
            <!-- class="dropdown closed"> -->
            <!-- <span class="input">{{application}}</span>
                <div class="arrow">▼</div>
                <div class="selection">
                    <p class="option application">No</p>
                    <p class="option application">Yes</p>
                </div> -->
            <input
              type="radio"
              ref="app"
              required
              v-model="app"
              name="app"
              class="radio"
              value="No"
            />
            {{ $t("input.No") }}
            <input
              type="radio"
              ref="app"
              v-model="app"
              name="app"
              class="radio"
              value="Yes"
            />
            {{ $t("input.Yes") }}
          </div>
        </div>
        <div class="row">
          <label for="refer"
            ><p>{{ $t("input.Who_Referred_You?") }}</p></label
          >
          <input
            name="refer"
            ref="refer"
            placeholder="e.g. Some Company Inc."
          />
        </div>
        <div class="row">
          <a class="cta">
            <input type="submit" class="submit" :value="$t('input.Submit')" />
          </a>
        </div>
         <div class="alert-wrapper">
            <div v-show="!flag" class="alert alert-danger" role="alert">
              Form was not submitted yet. <br/>
              Please click on the Submit button again.
            </div>
            <div v-show="flag" class="alert alert-success" role="alert">
              The form has been successfully submitted!
            </div>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import industries from "../industries.js";
import Email from "../smtp.js";
// import sendEmail from "../apis/user";

export default {
  name: "CDAPApplication",
  props: {
    clickEvents: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    clickEvents: {
      handler(clickEvent) {
        switch (clickEvent.target.className) {
          case "dropdown closed": {
            clickEvent.target.getElementsByClassName(
              "selection"
            )[0].style.maxHeight = "400px";
            clickEvent.target.className = "dropdown open";
            clickEvent.target.getElementsByClassName("arrow")[0].className =
              "arrow up";
            break;
          }
          case "dropdown open": {
            clickEvent.target.getElementsByClassName(
              "selection"
            )[0].style.maxHeight = "0px";
            clickEvent.target.className = "dropdown closed";
            clickEvent.target.getElementsByClassName("arrow")[0].className =
              "arrow";
            break;
          }
          case "option industry": {
            clickEvent.target.parentElement.style.maxHeight = "0px";
            clickEvent.target.parentElement.parentElement.className =
              "dropdown closed";
            this.activeIndustry = clickEvent.target.innerHTML;
            clickEvent.target.parentElement.parentElement.getElementsByClassName(
              "arrow"
            )[0].className = "arrow";
            break;
          }
          case "option application": {
            clickEvent.target.parentElement.style.maxHeight = "0px";
            clickEvent.target.parentElement.parentElement.className =
              "dropdown closed";
            this.application = clickEvent.target.innerHTML;
            break;
          }
        }
      },
    },
  },
  methods: {
    submitForm() {
      let data = `
            Name: ${this.$refs.name.value}<br/>
            Company Name: ${this.$refs.company_name.value}<br/>
            Business: ${this.$refs.business.value}<br/>
            Phone: ${this.$refs.phone.value}<br/>
            Email: ${this.$refs.email.value}<br/>
            Book a meeting: ${this.appBook}<br/>
            Find a time: ${this.$refs.calendar.value}<br/>
            Time zone: ${this.$refs.timezone.value}<br/>
            CDAP Application previously started: ${this.app}<br/>
            Referred by: ${this.$refs.refer.value}<br/>
        `;
      let obj = {
        SecureToken: "bb206819-c4dc-44fc-b279-0eddc0804cf4",
        To: "grace.h@inyourdaylight.com",
        From: "inyourdaylight123@gmail.com",
        Subject: "CDAP Application from Website",
        Body: data,
      };
      Email.send(obj).then((res) => {
        console.log(res);
        if (res == "OK") {
          this.flag = true;
        }
        this.$refs.form.reset();
      });
    },
    doIndustrySearch(e) {
      // Show dropdown
      e.target.parentElement.parentElement.getElementsByClassName(
        "selection"
      )[0].style.maxHeight = "400px";
      e.target.parentElement.className = "dropdown open";
      e.target.parentElement.getElementsByClassName("arrow")[0].className =
        "arrow up";
      // Current value is what user is typing in
      this.activeIndustry = e.target.value;
      // If not results found, display that in the dropdown
      if (
        [...industries.list].filter((i) =>
          i.toLowerCase().includes(e.target.value.toLowerCase())
        ).length === 0
      ) {
        this.industries = {
          list: ["No results found."],
        };
        return;
      }
      this.industries = {
        list: [...industries.list].filter((i) =>
          i.toLowerCase().includes(this.activeIndustry.toLowerCase())
        ),
      };
    },
    handleChange(clickEvent) {
      if ([...industries.list].indexOf(this.activeIndustry) < 0) {
        this.activeIndustry = null;
        this.industries = industries;
      }
      clickEvent.target.parentElement.getElementsByClassName(
        "selection"
      )[0].style.maxHeight = "0px";
      clickEvent.target.parentElement.className = "dropdown closed";
      clickEvent.target.parentElement.getElementsByClassName(
        "arrow"
      )[0].className = "arrow";
    },
    phoneMask() {   
      let num = this.$refs.phone.value.replace(/\D/g,''); 
      this.$refs.phone.value = num.substring(0,1) + '(' + num.substring(1,4) + ')' + num.substring(4,7) + '-' + num.substring(7,11); 
    }
    // handleFormChange(e){
    //     const { name, value } = e.target;
    //     this.data = { ...this.data, [name]: value };
    //     console.log(this.data);
    // },
    // handleSubmit(){
    //     sendEmail.cdap(this.data);
    // },
  },
  data() {
    return {
      industries: industries,
      activeIndustry: null,
      application: "No",
      data: {},
      prev: [],
      flag: false,
      appBook: null,
      app: null
    };
  },
  mounted() {
    this.activeIndustry = this.industries.list[0];
    this.flag = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";
input::placeholder {
  font-size: 14px;
}
input {
    font-size: 14px;
}
.cdap-application {
  padding: 20px;
}
.text {
  text-align: center;
}
.application-form {
  // background: $lightgrey;
  border: 1px solid $gold;
  max-width: 600px;
  margin: 40px auto auto;
  padding: 40px;
  @media screen and (max-width: $mobiledown) {
    padding: 20px;
    .row {
      display: flex;
      flex-direction: column;
    }
  }
  border-radius: 20px;
  input:not(.submit, .radio) {
    outline: none;
    border: none;
    border-radius: 0;
    padding: 10px 0 0;
    display: inline-block;
    vertical-align: middle;
    width: calc(75% - 10px);
    @media screen and (max-width: $mobiledown) {
      width: 100%;
    }
    background: none;
    border-bottom: 1px solid white;
    color: white;
    padding-bottom: 5px;
  }

  .cta {
    text-transform: capitalize;
    &:hover {
      .submit {
        color: $darkblue;
        transition: all 0.5s ease;
      }
    }
    background: white;
  }
  .submit {
    cursor: pointer;
    background: none;
    border: none;
    color: $turquoise;
    font-weight: 700;
    font-size: 20px;
    font-family: $body;
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
  }

  p {
    margin-bottom: 0;
  }
  .row:not(:last-child) {
    margin-bottom: 30px;
  }
  .row:nth-child(11) {
    text-align: center;
    margin-top: 40px;
  }
  h3 {
    margin: 0;
  }
  label {
    display: inline-block;
    vertical-align: middle;
    width: calc(25% - 20px);
    @media screen and (max-width: $mobiledown) {
      width: 100%;
    }
    padding-right: 10px;
    color: white;
  }
  .radiobox {
    width: calc(75% - 10px);
    display: inline-block;
    .radio {
      cursor: pointer;
      display: inline-block;
      border: none;
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
  .dropdown {
    cursor: pointer;
    // background: lightblue;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
    border: none;
    border-radius: 10px;
    width: calc(75% - 10px);
    display: inline-block;
    background: white;
    position: relative;
    input {
      color: $darkblue;
      padding: 5px;
      border-radius: 10px;
      transition: all 0.5s ease;
      color: $darkblue;
      &:focus,
      &:hover {
        background: lightgrey;
        transition: all 0.5s ease;
      }
    }
    .input {
      padding: 0;
      color: $darkblue;
      padding: 5px;
      border-radius: 10px;
      transition: all 0.5s ease;
      color: $darkblue;
      font-size: 14px;
      display: block;
      pointer-events: none;
    }
    p {
      color: $darkblue;
      font-size: 14px;
    }
    .arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: black;
      pointer-events: none;
      transition: all 0.25s ease;
    }
    .up {
      transition: all 0.25s ease;
      transform: translateY(-50%) rotate(180deg);
    }
    .selection {
      position: absolute;
      background: lightgrey;
      width: calc(100% - 20px);
      left: 0;
      z-index: 3;
      max-height: 0px;
      overflow-y: scroll;
      transition: all 0.5s ease;
      p {
        transition: all 0.25s ease;
        padding: 5px 10px;
        &:hover {
          background: white;
          transition: all 0.25s ease;
        }
      }
    }
    option {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background: black;
      color: white;
    }
  }
}
.alert-wrapper {
  @import "~bootstrap/scss/bootstrap.scss";
}
input[type="datetime-local"] {
  position: relative;
  padding-left: 1.8rem !important;
  box-sizing: border-box;
  /* text-align: center; */
}
::-webkit-datetime-edit-text {
  margin: 0 5px 0 5px;
}
::-webkit-calendar-picker-indicator {
  font-size: 1.5rem;
  filter: invert(1);
  cursor: pointer;
  position: absolute;
  left: 0;
}
</style>
