<template>
  <div id="inner">
      <component v-for="(item, i) in components" :key="i" :is="item.component" :id="item.component+i" :data="item.data"></component>
    </div>
    <FooterNav></FooterNav>
</template>

<script>
import {toRaw} from "vue";
export default {
  name: 'App',
  components: {
  },
  props: {
    scrollPos: {
      type: Number,
      default: 0
    },
    clickEvents: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    scrollPos: {
      handler(scrollPos) {
        // console.log(scrollPos);
        let scrolledComponent = document.getElementById(toRaw(this.componentsIntegered)[this.positions.filter(item => scrollPos >= item).length - 1]);
        if (toRaw(this.positions).filter(item => scrollPos >= item).length > 0 && !scrolledComponent.className.includes("fadein")) {
          scrolledComponent.className += " fadein";
        }
      }
    }
   
  },
  methods: {
  },
  data() {
    return {
      components: [
        {
          component: 'HomepageBanner',
          data: {}
        },
        {
          component: 'ImagesGallery',
          data: {
            images: [
              {
                  // src: require("./assets/iyd1.png"),
                  link: '',
                  offset: {x: 0, y: 0}
              },
              {
                  // src: require("./assets/CDAP-image.png"),
                  link: '',
                  offset: {x: -80, y: 100}
              },
              {
                  // src: require("./assets/goals.png"),
                  link: '',
                  offset: {x: 0, y: 0}
              }
            ],
            cta_text: 'App.cta_text',
            text1: 'App.text1',
            text2: 'App.text2',
            cta: {
              link: '/cdap',
              text: 'App.button'
            }
          }
        },
        {
          component: 'CarouselText',
          data: {}
        },
        {
          component: 'GoldSquareSection',
          data: {
            title: "Clear Growth Strategy For Your Future.",
            stagger_title: true,
            text: "You need to have a clear vision for your company and a well-thought-out marketing strategy in order to grow sustainably. A strong business plan will help keep your company on track as you make decisions about where to allocate resources and energy, bring on partners, evaluate new opportunities and much more!",
            image: ''
          }
        },
        {
          component: 'ImagesGallery',
          data: {
            images: [
              {
                  // src: require("./assets/iyd3.png"),
                  link: '',
                  offset: {x: 0, y: 0}
              },
              {
                  // src: require("./assets/iyd6.png"),
                  link: '',
                  offset: {x: -80, y: 100}
              },
              {
                  // src: require("./assets/iyd5.png"),
                  link: '',
                  offset: {x: 0, y: 0}
              }
            ],
            text1: 'App.ImagesGallery.text1',
            text2: 'App.ImagesGallery.text2',
            text3: 'App.ImagesGallery.text3',
            cta_text: 'App.ImagesGallery.cta_text',
            cta: {
              link: '/services',
              text: 'App.ImagesGallery.text'
            },
            text_on_top: true
          }
        },
        // {
        //   component: 'TextandFadedBG',
        //   data: {
        //     title: "CRM",
        //     text: "Customer satisfaction is the number one measure of success for your business"
        //   }
        // }
      ],
      componentsIntegered: [],
      positions: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.positions = [...this.components].map((id,i) => {
        let component = document.getElementById(id.component+i);
        // return (component.getBoundingClientRect().top - ((window.innerHeight - component.getBoundingClientRect().height) / 8)) + window.scrollY;
        return component.getBoundingClientRect().top - (window.innerHeight * .5);
      })
      // console.log(this.positions);
    })
    
    this.componentsIntegered = [...this.components].map((item, i) => item.component+i);
  
  }
}
</script>

<style lang="scss">
@import "fonts.scss";
@import "styles.scss";
</style>