<template>
  <div class="languages">
    <ul>
      <li class="active">
        <a
          href="#"
          @click.prevent="setLocale('en')"
          ref="account"
          @keydown.up.exact.prevent=""
          @keydown.tab.exact="focusNext(false)"
          @keydown.down.exact.prevent="focusNext(true)"
          @keydown.esc.exact="hideDropdown"
        >
          EN
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="setLocale('fr')"
          @keydown.shift.tab="focusPrevious(false)"
          @keydown.up.exact.prevent="focusPrevious(true)"
          @keydown.down.exact.prevent=""
        >
          <router-link :to="`/${$i18n.locale}/contact-us`">FR</router-link>
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="setLocale('kr')"
          @keydown.shift.tab="focusPrevious(false)"
          @keydown.up.exact.prevent="focusPrevious(true)"
          @keydown.down.exact.prevent=""
        >
          한국어
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="setLocale('zh')"
          @keydown.shift.tab="focusPrevious(false)"
          @keydown.up.exact.prevent="focusPrevious(true)"
          @keydown.down.exact.prevent=""
        >
          <router-link :to="`/${$i18n.locale}/contact-us`">中文</router-link>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      locale: "",
    };
  },
  watch: {
    locale: function (val) {
      localStorage.setItem("locale", val);
    },
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.isVisible) {
        // this.$refs.account.focus()
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
      this.locale = locale;
      if (locale == "zh" || locale == "fr") {
        // alert('Unavailable FR & CH should direct people to the Contact Us page with a little tweak.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles.scss";
.languages {
  @media screen and (max-width: 300px) {
    top: 50vh !important;
  }
  @media screen and (min-width: 301px) and (max-width: 700px) {
    top: 85vw !important;
  }
  @media screen and (max-width: $mobiledown) {
    /* display: none; */
    top: 60vw;
    a {
      color: black;
    }
    ul {
      margin-left: -18px;
    }
  }
  position: fixed;
  left: 20px;
  top: 50vh;
  z-index: 10;
  transform: translateY(-50%);
  ul {
    list-style: none;
    padding: 0;
    line-height: 1.5;
  }
  .active {
    color: $gold;
  }
  a {
    text-decoration: none;
  }
  .router-link-active.router-link-exact-active {
    background-color: transparent;
    color: #ffb41f !important;
    @media screen and (max-width: $mobiledown) {
      color: black !important;
    }
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
}
</style>
