<template>
  <div :class="`text-and-image ${data.orientation} ${data.textalign}`">
    <!-- options will be  -->
    <div class="col image animate-fade-in">
      <img :src="data.image" />
      <p class="ref-right" v-html="$t(data.ref)"></p>
    </div>
    <div class="col text animate-fade-in animate-delay-250">
      <small v-show="data.date">{{ $t(data.date) }}</small>
      <h2>{{ $t(data.title) }}</h2>
      <img class="shopify" v-show="data.img" :src="data.img" />
      <p class="subtitle" v-html="$t(data.text)"></p>
      <div class="ctas" v-show="data.cta_buttons">
        <router-link
          v-for="(item, i) in data.cta_buttons"
          :key="i"
          class="cta"
          :to="`/${$i18n.locale}${item.link}`"
          
        >
          <p>{{ $t(item.text) }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    clickEvents: {
      type: Object,
      default: () => {},
    },
  },
  watch: {},
  methods: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";
@import "../fonts.scss";
.ref-right {
  text-align: right;
}
.text-and-image {
  padding: 30px 60px;
  @media screen and (max-width: $mobiledown) {
    flex-direction: column !important;
    padding: 30px 20px;
  }
}
.image {
  text-align: center;
  img {
    background-color: white;
    border-radius: 10px;
    width: auto;
    height: auto;
    @media screen and (min-width: $mobileup) {
      max-height: 400px;
      max-width: 40vw;
    }
    margin: auto;
    @media screen and (max-width: $mobiledown) {
      margin-bottom: 20px;
      max-width: 100%;
    }
  }
}
.shopify {
  width: 70%;
  margin-right: 8vw;
  margin-right: 18%;
  margin-bottom: 2%;
  background-color: white;
  border-radius: 10px;
  padding: 3% 1%;
  @media screen and (max-width: $mobiledown) {
    margin-left: 0 !important;
  }
}
.text-align-right {
  .text {
    text-align: right;
  }
  @media screen and (max-width: $mobiledown) {
    .text {
      text-align: left;
    }
  }
}
.text-align-left {
  .text {
    text-align: left;
  }
}
.text-and-image {
  display: flex;
  align-items: center;
  justify-content: center;
  .col {
    // padding: 0 20px;
    width: 100%;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    p,
    ul,
    ol,
    small,
    .ctas {
      @media screen and (min-width: $mobileup) {
        width: 80%;
        margin: auto;
        display: block;
      }
    }
    h2 {
      margin-bottom: 20px;
    }
    small {
      margin-bottom: 10px;
      opacity: 0.75;
    }
    // flex: 1;
  }
}
.text-on-left {
  img {
    margin-right: auto;
    display: block;
  }
  flex-direction: row-reverse;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p,
  ul,
  ol,
  small,
  .ctas {
    margin-left: auto;
    // margin-left: auto;
  }
}
.text-on-right {
  img {
    margin-left: auto;
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p,
  ul,
  ol,
  small,
  .ctas {
    margin-right: auto;
    // margin-left: auto;
  }
}
.ctas {
  padding-top: 30px;
}
.subtitle:first-letter {
  text-transform: uppercase;
}
</style>
