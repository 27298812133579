<template>
  <div class="contactform">
    <div class="col animate-fade-in">
      <div class="text">
        <h1>{{ $t("contact.title") }}</h1>
        <p>{{ $t("contact.text") }}</p>
        <div class="contacts">
          <p>
            <a href="mailto:info@inyourdaylight.com"
              ><span><img class="icon" src="../assets/Email.svg" /></span>
              info@inyourdaylight.com</a
            >
          </p>
          <p>
            <a href="tel:6473587767">
              <span><img class="icon" src="../assets/Phone.svg" /></span>
              (647)-358-7767</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="contact-form animate-fade-in animate-delay-250">
        <form @submit.prevent="submitForm" ref="form">
          <div class="row">
            <label for="name"
              ><p>{{ $t("contact.input.Name") }}</p></label
            >
            <input
              name="name"
              ref="name"
              required
              placeholder="e.g. John Smith"
            />
          </div>
          <div class="row">
            <label for="email"
              ><p>{{ $t("contact.input.Email_Address") }}</p></label
            >
            <input
              type="email"
              name="email"
              ref="email"
              required
              placeholder="e.g. johnsmith@email.com"
            />
          </div>
          <div class="row">
            <label for="phone"
              ><p>{{ $t("contact.input.Phone_Number") }}</p></label
            >
            <input
              @keyup="phoneMask"
              type="tel"
              name="phone"
              ref="phone"
              required
              placeholder="e.g. 1(647)358-7767"
            />
          </div>
          <div class="row">
            <label for="app"
              ><p>{{ $t("contact.input.app") }}</p></label
            >
            <div name="app" class="radiobox">
              <!-- class="dropdown closed"> -->
              <!-- <span class="input">{{application}}</span>
                <div class="arrow">▼</div>
                <div class="selection">
                    <p class="option application">No</p>
                    <p class="option application">Yes</p>
                </div> -->
              <input
                type="radio"
                ref="app"
                required
                v-model="app"
                name="app"
                class="radio"
                value="No"
              />
              {{ $t("contact.input.No") }}
              <input
                type="radio"
                ref="app"
                v-model="app"
                name="app"
                class="radio"
                value="Yes"
              />
              {{ $t("contact.input.Yes") }}
            </div>
          </div>
          <div class="row">
            <label for="calendar"
              ><p>{{ $t("contact.input.calendar") }}</p></label
            >
            <input
              type="datetime-local"
              name="calendar"
              ref="calendar"
            />
          </div>
          <div class="row">
            <label for="timezone"><p>Time zone</p></label>
            <input
              name="timezone"
              ref="timezone"
              placeholder="e.g. EST"
            />
          </div>
          <div class="row">
            <label for="message"
              ><p>{{ $t("contact.input.message") }}</p></label
            >
            <textarea
              name="message"
              ref="message"
              required
              placeholder="e.g. I have an inquiry about your services..."
              rows="10"
              cols="70"
            ></textarea>
          </div>

          <div class="row">
            <a class="cta">
              <input type="submit" class="submit" :value="$t('input.Submit')" />
            </a>
          </div>
          <div class="alert-wrapper">
            <div v-show="!flag" class="alert alert-danger" role="alert">
              Form was not submitted yet. <br/>
              Please click on the Submit button again.
            </div>
            <div v-show="flag" class="alert alert-success" role="alert">
              The form has been successfully submitted!
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Email from "../smtp.js";
// import sendEmail from "../apis/user";

export default {
  name: "ContactForm",
  props: {
    clickEvents: {
      type: Object,
      default: () => {},
    },
  },
  watch: {},
  methods: {
    submitForm() {
      let data = `
        Name: ${this.$refs.name.value}<br/>
        Email: ${this.$refs.email.value}<br/>
        Phone: ${this.$refs.phone.value}<br/>
        Book a meeting: ${this.app}<br/>
        Find a time: ${this.$refs.calendar.value}<br/>
        Time zone: ${this.$refs.timezone.value}<br/>
        Message: ${this.$refs.message.value}<br/>
      `;
      let obj = {
        SecureToken: "bb206819-c4dc-44fc-b279-0eddc0804cf4",
        To: "grace.h@inyourdaylight.com",
        From: "inyourdaylight123@gmail.com",
        Subject: "Contact Form from Website",
        Body: data,
      };
      Email.send(obj).then((res) => {
        console.log(res);
        if (res == "OK") {
          this.flag = true;
        }
        this.$refs.form.reset();
      });
    },
    phoneMask() {   
      let num = this.$refs.phone.value.replace(/\D/g,''); 
      this.$refs.phone.value = num.substring(0,1) + '(' + num.substring(1,4) + ')' + num.substring(4,7) + '-' + num.substring(7,11); 
    }
    // handleFormChange(e){
    //     const { name, value } = e.target;
    //     this.prev = this.data;
    //     this.data.push({ ...this.prev, [name]: value });
    // },
    // handleSubmit(){
    //     sendEmail.contact(this.data);
    // },
  },
  data() {
    return {
      flag: false,
      app: null
    };
  },
  mounted() {
    this.flag = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../styles.scss";

input::placeholder {
  font-size: 14px;
}
input {
  font-size: 14px;
}
textarea::placeholder {
  font-size: 14px;
}
textarea {
  font-size: 14px;
}
.contactform {
  padding: 20px;
  @media screen and (min-width: $mobileup) {
    padding: 20px 100px;
  }
}
.text {
  text-align: left;
  max-width: 600px;
  margin: auto;
  @media screen and (max-width: $mobiledown) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: $mobileup) {
    margin-right: 20px;
  }
}
.contact-form {
  // background: $lightgrey;
  border: 1px solid $gold;
  max-width: 600px;
  margin: auto;
  padding: 40px;
  @media screen and (max-width: $mobiledown) {
    padding: 20px;
    .row {
      display: flex;
      flex-direction: column;
    }
  }
  border-radius: 20px;
  input:not(.submit, .radio),
  textarea {
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 10px 0 5px;
    display: inline-block;
    vertical-align: top;
    width: calc(75% - 10px);
    @media screen and (max-width: $mobiledown) {
      width: 100%;
    }
    background: none;
    border-bottom: 1px solid white;
    color: white;
  }
  textarea {
    resize: none;
    border: 1px solid white;
    padding: 10px;
    width: calc(75% - 20px);
    @media screen and (max-width: $mobiledown) {
      width: calc(100% - 20px);
    }
    border-radius: 10px;
    font-family: $body;
  }

  .cta {
    text-transform: capitalize;
    &:hover {
      .submit {
        color: $darkblue;
        transition: all 0.5s ease;
      }
    }
    background: white;
  }
  .submit {
    cursor: pointer;
    background: none;
    border: none;
    color: $turquoise;
    font-weight: 700;
    font-size: 20px;
    font-family: $body;
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
  }
  p {
    margin-bottom: 0;
  }
  .row:not(:last-child) {
    margin-bottom: 20px;
  }
  .row:nth-child(8) {
    text-align: center;
    margin-top: 40px;
  }
  h3 {
    margin: 0;
  }
  label {
    display: inline-block;
    vertical-align: middle;
    width: calc(25% - 20px);
    @media screen and (max-width: $mobiledown) {
      width: 100%;
    }
    padding: 10px 0 5px;
    padding-right: 10px;
    color: white;
  }
  .radiobox {
    width: calc(75% - 10px);
    display: inline-block;
    .radio {
      cursor: pointer;
      display: inline-block;
      border: none;
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
  .dropdown {
    cursor: pointer;
    // background: lightblue;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
    border: none;
    border-radius: 10px;
    width: calc(75% - 10px);
    display: inline-block;
    background: white;
    position: relative;
    input {
      color: $darkblue;
      padding: 5px;
      border-radius: 10px;
      transition: all 0.5s ease;
      color: $darkblue;
      &:focus,
      &:hover {
        background: lightgrey;
        transition: all 0.5s ease;
      }
    }
    .input {
      padding: 0;
      color: $darkblue;
      padding: 5px;
      border-radius: 10px;
      transition: all 0.5s ease;
      color: $darkblue;
      font-size: 14px;
      display: block;
      pointer-events: none;
    }
    p {
      color: $darkblue;
      font-size: 14px;
    }
    .arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: black;
      pointer-events: none;
      transition: all 0.25s ease;
    }
    .up {
      transition: all 0.25s ease;
      transform: translateY(-50%) rotate(180deg);
    }
    .selection {
      position: absolute;
      background: lightgrey;
      width: calc(100% - 20px);
      left: 0;
      z-index: 3;
      max-height: 0px;
      overflow-y: scroll;
      transition: all 0.5s ease;
      p {
        transition: all 0.25s ease;
        padding: 5px 10px;
        &:hover {
          background: white;
          transition: all 0.25s ease;
        }
      }
    }
    option {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background: black;
      color: white;
    }
  }
}
.col {
  display: inline-block;
  vertical-align: top;
  @media screen and (min-width: $mobileup) {
    width: 50%;
  }
  @media screen and (max-width: $mobiledown) {
    width: 100%;
    display: block;
  }
}
.contacts {
  width: 100%;
  margin-top: 60px;
  td {
    @media screen and (min-width: $mobileup) {
      width: 50%;
    }
    @media screen and (max-width: $mobiledown) {
      width: 100%;
      display: block;
    }
  }
}
h1 {
  margin-bottom: 40px;
}
.icon {
  width: 20px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}
.alert-wrapper {
  @import "~bootstrap/scss/bootstrap.scss";
}
input[type="datetime-local"] {
  position: relative;
  padding-left: 1.8rem !important;
  box-sizing: border-box;
  /* text-align: center; */
}
::-webkit-datetime-edit-text {
  margin: 0 5px 0 5px;
}
::-webkit-calendar-picker-indicator {
  font-size: 1.5rem;
  filter: invert(1);
  cursor: pointer;
  position: absolute;
  left: 0;
}
</style>
